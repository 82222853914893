import Header from '@components/_organism/Header';
import MobileHeader from '@components/_organism/MobileHeader';
import styled from '@emotion/styled';
import { useIsMobile, useIsTablet } from '@hooks/responsive/useIsMobile';
import { ReactNode, useEffect } from 'react';
import { useRouter } from 'next/router';
import { HOME } from '@constants/routes/routes';
import Footer from '@components/_organism/Footer';
import MobileFooter from '@components/_organism/MobileFooter';
import { gray } from '@styles/Colors';

type Props = {
  readonly children: ReactNode;
  readonly backgroundGray?: boolean;
  readonly isTransparent?: boolean;
};

declare global {
  interface Window {
    dataLayer?: any;
    CallMtm?: any;
  }
}

const Layout = styled.div`
  width: 100%;
  min-height: 100vh;

  /* iOS only */
  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;
  }

  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
`;

const Main = styled.div<{ backgroundGray?: boolean }>`
  width: 100%;
  flex: 1;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;

  ${({ backgroundGray }) =>
    backgroundGray &&
    `
    background: ${gray.gray1};
  `}
`;

export default function DefaultLayout({ children, backgroundGray, isTransparent = false }: Props) {
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  const router = useRouter();
  const isHome = router.pathname === HOME;

  return (
    <Layout>
      <HeaderWrapper>
        {isTablet ? <MobileHeader isTransparent={isTransparent} /> : <Header isTransparent={isTransparent} />}
      </HeaderWrapper>

      <Main backgroundGray={backgroundGray}>{children}</Main>

      {/* only Home */}
      {isHome && (isMobile ? <MobileFooter /> : <Footer />)}
    </Layout>
  );
}
